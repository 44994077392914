 // stary kde su tabulka slider a ostatne slide tabulky mimo
 let configData;
 let swiper; // Declare swiper variable globallyFrew

 let transitionSpeed; // Global variable to store transition speed

 
 import {
     populateMenu
 } from './menu.js'; // Importovanie exportovanej funkcie
 import {
     loadMedia
 } from './media.js';


 // Toggle button event listener
 const toggleButton = document.getElementById('toggle');
 toggleButton.addEventListener('click', function() {

    const playIcon = document.getElementById('playIcon');
    const pauseIcon = document.getElementById('pauseIcon');
    const wrapper = document.getElementById('wrapper');
    
     const nav = document.querySelector('nav');
     const isOpen = this.checked;

     if (swiper) { // Overenie, či je inštancia Swiper definovaná
        
        swiper.autoplay.stop(); // Zastav autoplay (ak je povolený)
        wrapper.innerHTML = ''; 
    }

     if (isOpen) {
        playIcon.style.display = 'block';
        pauseIcon.style.display = 'none';
		 
         openMenu();
     } else {
        playIcon.style.display = 'none';
            pauseIcon.style.display = 'block';
         closeMenu();
     }

    
 }, {
     passive: true
 }); // Označenie ako 'passive'


 // Function to open the menu
 export function openMenu() {
     const nav = document.querySelector('nav');

     nav.style.zIndex = "1005";
     nav.style.opacity = "1";
     nav.style.visibility = "visible";
 }

 // Function to close the menu
 export function closeMenu() {
     const nav = document.querySelector('nav');
     nav.style.zIndex = "-1";
     nav.style.opacity = "0";
     nav.style.visibility = "hidden";
 }

 // Open menu initially on page load
 openMenu();
 
 document.addEventListener("DOMContentLoaded", () => {

    const urlInput = document.getElementById('websheet-url');
    const storedUrl = localStorage.getItem('websheetURL');
    
    if (urlInput && storedUrl) {
        urlInput.value = storedUrl;
    }

    const baseUrlInput = document.getElementById('base-url');
    const storedBaseUrl = localStorage.getItem('mediaBaseUrl');
    
    if (baseUrlInput && storedBaseUrl) {
        baseUrlInput.value = storedBaseUrl;
    }

   
	 
    document.addEventListener('keydown', (event) => {
        const menu = document.querySelector("#cover");
    
        // Skontrolujte, či má menu z-index menší než 0
        const isMenuHidden = menu && parseInt(getComputedStyle(menu).zIndex, 10) < 0;
    
        // Ak je menu skryté, reagujte na stlačenie klávesy Enter
        if (isMenuHidden && (event.key.toLowerCase() === 'enter' || event.key.toLowerCase() === 'e')) {
    // Toggle checked stav toggle button
            toggleButton.checked = !toggleButton.checked;
    
            // Otvorenie alebo zatvorenie menu na základe nového stavu
            if (toggleButton.checked) {
                openMenu();
                const wrapper = document.getElementById("wrapper");

                // Clear previous slides
                wrapper.innerHTML = "";
            } else {
                closeMenu();
            }
        }
    });
    

    const playSwiperButton = document.getElementById('playSwiper');
    const playIcon = document.getElementById('playIcon');
    const pauseIcon = document.getElementById('pauseIcon');

    // Funkcia na prepínanie prehrávania
const togglePlayPause = () => {
    if (swiper && swiper.autoplay) { // Kontrola, či swiper a autoplay existujú
        if (swiper.autoplay.running) {
            swiper.autoplay.stop();
            playIcon.style.display = 'block';
            pauseIcon.style.display = 'none';
        } else {
            swiper.autoplay.start();
            playIcon.style.display = 'none';
            pauseIcon.style.display = 'block';
        }
    } else {
        console.warn('Swiper or Swiper Autoplay not initialized'); // Zobrazí varovanie namiesto chyby
    }
};
    
    // Event listener na kliknutie tlačidla
    playSwiperButton.addEventListener('click', togglePlayPause);

    
    
    
    // Funkcia pre prepínanie pomocou klávesy 'P'
    document.addEventListener('keydown', (event) => {
        if (event.key.toLowerCase() === 'p') {
            togglePlayPause();
        }
    });

    document.getElementById('hideMenu').addEventListener('click', function() {
		
        toggleMenu(); // Call the function to toggle menu
    });

    // Function to toggle menu visibility
    function toggleMenu() {
        const menu = document.getElementById('conatainerMenuFirst');
        menu.classList.toggle('hiddenMenu');
        const arrowIcon = document.getElementById('arrowIcon');
        
        // Change arrow direction
        if (menu.classList.contains('hiddenMenu')) {
            // Arrow left
            arrowIcon.innerHTML = '<path d="M9 6l-6 6 6 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>';
        } else {
            // Arrow right
            arrowIcon.innerHTML = '<path d="M15 18l6-6-6-6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>';
        }
    }

    // Map the 'H' key to toggle the menu
    document.addEventListener('keydown', function(event) {
        if (event.key === 'h' || event.key === 'H') {
            toggleMenu(); // Call the function to toggle menu
        }
    });

    // Event listener for reload button
document.getElementById("reloadSwiper").addEventListener("click", function() {
    togglePlayPause(); // Toggle play/pause
    reloadSwiperWithNewSheet(); // Reload swiper with new sheet data
});


// Event listener for key press (R key)
document.addEventListener('keydown', function(event) {
    if (event.key === 'r' || event.key === 'R') {
        const sheetName = 'YourSheetNameHere'; // Replace with your actual sheet name
        reloadSwiperWithNewSheet(sheetName);
    }
});

   // Predvolená URL
   const defaultURL = "https://docs.google.com/spreadsheets/d/19h5c4ScUpjowl1_Ty65pvSTQ_PzCgZ-fcgME1UMDpsc/edit?usp=sharing";
            
   // Načítanie URL z Local Storage
   const storedURL = localStorage.getItem('websheetURL');
   const urlToUse = storedURL ? storedURL : defaultURL; // Použitie uloženej URL alebo predvolenej URL


     // Fetch menu data from 'menu' sheet
     websheet("menu", {
         caching: false,
         url: urlToUse,
         sheet: "menu",
         onLoaded: function(data) {
             if (!data || data.length === 0) {
                 console.error("No menu data loaded from the websheet");
                 return;
             }

             // Kontrola parametra v URL
        const urlParams = new URLSearchParams(window.location.search); 
        const product = urlParams.get('p');
        

        if (product) {
            // Ak je parameter 'product' prítomný, nastav hodnotu `sheetNameInput`
            const sheetNameInput = document.getElementById("sheetNameInput");
            sheetNameInput.value = product;
          //  const mediaContainer = document.getElementById('media-container');
          //  mediaContainer.innerHTML = '';
            const fullscreenContainer = document.getElementById('fullscreenContainer');
           
            
            reloadSwiperWithNewSheet();
            closeMenu(); // Zatvorenie navigačného menu po kliknutí
            populateMenu(data);

            fullscreenContainer.style.display = "none"; // Skryť kontajner po aktivácii
        } else {
            // Ak parameter 'product' nie je prítomný, vykoná sa populateMenu(data)
            populateMenu(data);
        }
         

         },
         onError: function(error) {
             console.error("Error loading menu data from websheet:", error);
         }
     });
 });

 function fetchSlidesData(sheetName) {


  // Predvolená URL
  const defaultURL = "https://docs.google.com/spreadsheets/d/19h5c4ScUpjowl1_Ty65pvSTQ_PzCgZ-fcgME1UMDpsc/edit?usp=sharing";
            
  // Načítanie URL z Local Storage
  const storedURL = localStorage.getItem('websheetURL');
  const urlToUse = storedURL ? storedURL : defaultURL; // Použitie uloženej URL alebo predvolenej URL
    

     websheet("websheet", {
         caching: false,
         url: urlToUse,
         sheet: sheetName,
         onLoaded: function(data) {
             if (!data || data.length === 0) {
                 console.error("No product data loaded from the websheet");
                 return;
             }

             // Separate configuration data
             const configData = {
                 autoplay: String(data[0].autoplay?.value).toUpperCase() === "TRUE",
                 freemode: String(data[0].freemode?.value).toUpperCase() === "TRUE",
                 speed: parseInt(data[0].speed?.value) || 200,
                 transition: parseInt(data[0].transition?.value) || 1000,
				 stype: parseInt(data[0].stype?.value) || 'fade', // Change here to handle string directly
             };

             const copyslider = document.getElementById('sendButton'); 
            
             if (copyslider) {
                 copyslider.addEventListener('click', function() {
                     // Získať aktuálnu URL
                     const currentUrl = new URL(window.location.href);
                     const urlParams = new URLSearchParams(currentUrl.search);
 
                     // Pridať alebo aktualizovať parameter sheetName
                     urlParams.set('p', sheetName);
                     const newUrl = `${currentUrl.origin}${currentUrl.pathname}?${urlParams.toString()}`;
 
                     // Skopírovať URL do schránky
                     copyToClipboard(newUrl);
                    // console.log("URL with sheet name copied to clipboard:", newUrl);
                 });
             }


             // Fetch slides data starting from the third row
             const slidesData = data.slice(1);

             // Získať poradie slideov z tabuľky
            const order = data[0].order?.value; // Predpokladám, že 'order' je názov stĺpca v tabuľke
            const slidesOrder = order ? order.split(',').map(num => parseInt(num.trim())) : null;

             // Ak je poradie definované, použijeme ho na vytvorenie slideov
             const slidesToDisplay = slidesOrder ? slidesOrder.map(index => slidesData[index - 1]) : slidesData;


           

             function adjustFontSize(element) {
                 const text = element.textContent || element.innerText;
                 const wordCount = text.split(/\s+/).length;
                 let fontSize = 10; // Default font size
                 let maxWidth = '600px'; // Default max-width

                 if (wordCount < 3) {
                     fontSize = 4.5;
                 } else if (wordCount < 5) {
                     fontSize = 4;
                 } else if (wordCount < 10) {
                     fontSize = 3.7;
                 } else if (wordCount < 15) {
                     fontSize = 3.3;
                 } else if (wordCount < 20) {
                     fontSize = 3;
                 } else if (wordCount < 30) {
                     fontSize = 2.5;
                 } else if (wordCount < 100) {
                     fontSize = 3;
                 } else if (wordCount < 150) {
                     fontSize = 2.5;
                 } else if (wordCount < 200) {
                     fontSize = 2.2;

                 } else {
                     fontSize = 1;
                 }

                 if (wordCount > 40) {
                     maxWidth = '700px'; // Nastaviť max-width na 50% pre väčšie množstvo textu
                 }

                 if (wordCount > 50) {
                     maxWidth = '100%'; // Nastaviť max-width na 50% pre väčšie množstvo textu
                 }

                 element.style.fontSize = fontSize + 'vw';
                 element.style.maxWidth = maxWidth;
             }
             // console.log("products =", data);
             const wrapper = document.getElementById("wrapper");

             // Clear previous slides
             wrapper.innerHTML = "";

             // Create slides dynamically
            // Create slides dynamically
slidesToDisplay.forEach((item, index) => {
    const slideDiv = document.createElement("div");
    slideDiv.classList.add('swiper-slide', 'swContainer', 'image-container-effect', item.tposition?.value);

    const headTextContainer = document.createElement("div");
    headTextContainer.classList.add('headTextContainer', item.tstyle?.value);

    const headDiv = document.createElement("div");
    headDiv.className = "head";
    headDiv.textContent = item.title?.value || "";
    headTextContainer.appendChild(headDiv);

    const headText = document.createElement("div");
    headText.className = "headText";
    headText.textContent = item.text?.value || "";
    headTextContainer.appendChild(headText);
    adjustFontSize(headText);

    if (!item.text?.value || !item.text.value.trim()) {
        headTextContainer.removeChild(headText);
    }

    slideDiv.appendChild(headTextContainer);

    // Set background color from sheet data
    const bgColor = item.bgcolor?.value || "black"; // Default to black if bgcolor is not specified
    slideDiv.style.backgroundColor = bgColor;

    // Handle slide type
    if (item.type.value === "text") {
        // No specific rendering needed for "text"
    } else if (item.type.value === "video") {
        const video = document.createElement("video");
        video.src = item.url?.value || "";
        video.loop = item.loop.value === "on";
        video.muted = item.mute.value === "on";
        video.classList.add('apply-flash-effect', item.effect?.value || "");
        video.style.animationDuration = item.mspeed?.value || "5s";

        if (item.portrait?.value === true || item.portrait?.value === "TRUE" || item.portrait?.value === "true") {
            video.classList.add('portrait');
        }    
        slideDiv.appendChild(video);
    }else if (item.type.value === "image" || item.type.value === "imageaudio") {
    // Add image to the slide
    const img = document.createElement("img");
    img.src = item.url?.value || "";
    img.classList.add('apply-flash-effect', item.effect?.value || "");
    img.style.animationDuration = item.mspeed?.value || "5s";

  //  console.log(`Spracovávam slide č.${index + 1}, typ: ${item.type.value}`);
  //  console.log("URL obrázka:", item.url?.value);

    if (item.portrait?.value === true || item.portrait?.value === "TRUE" || item.portrait?.value === "true") {
        img.classList.add('portrait');
        //console.log("Pridaný režim 'portrait'.");

        // Add blurred background layer for portrait mode
        const bgDiv = document.createElement("div");
        bgDiv.style.backgroundImage = `url(${item.url.value})`;
        bgDiv.style.backgroundSize = "cover";
        bgDiv.style.backgroundPosition = "center";
        bgDiv.style.filter = "blur(20px)";
        bgDiv.classList.add('background-layer');
        slideDiv.appendChild(bgDiv);
    }

    slideDiv.appendChild(img);
    //console.log(`Spracovaný typ: ${item.type.value} (obrázok pridaný).`);

    // Handle audio if type is "imageaudio"
    if (item.type.value === "imageaudio") {
      

        if (item.aurl?.value) {
            const audio = document.createElement("audio");
            audio.src = item.aurl.value;
            audio.loop = item.loop.value === "on";
            audio.controls = false; // Hide controls
            audio.autoplay = true; // Auto-play the audio
            audio.style.display = "none"; // Ensure the player is hidden visually
  
            slideDiv.appendChild(audio);
           // console.log("Typ 'imageaudio': audio bolo pridané a nastavené na autoplay.");
        } else {
           // console.warn("Typ 'imageaudio', ale URL audia nie je definovaná.");
        }    
    }
} else if (item.type.value === "audio") {
    const audioContainer = document.createElement("div");
    audioContainer.className = "audio-controls";

    // Pridanie obrázka, ak je dostupný
    if (item.url?.value) {
        const img = document.createElement("img");
        img.src = item.url.value;
        img.classList.add('apply-flash-effect');
        img.style.animationDuration = item.mspeed?.value || "5s";

        // Ak je obrázok v portrait režime, pridáme pozadie
        if (item.portrait?.value === true || item.portrait?.value === "TRUE" || item.portrait?.value === "true") {
            img.classList.add('portrait');

            const bgDiv = document.createElement("div");
            bgDiv.style.backgroundImage = `url(${item.url.value})`;
            bgDiv.style.backgroundSize = "cover";
            bgDiv.style.backgroundPosition = "center";
            bgDiv.style.filter = "blur(20px)";
            bgDiv.classList.add('background-layer');
            slideDiv.appendChild(bgDiv);
        }

        slideDiv.appendChild(img);
      //  console.log("Pridaný obrázok k audio typu.");
    }

    // Audio player
    const audio = document.createElement("audio");
    audio.src = item.aurl?.value || "";
    audio.loop = item.loop.value === "on";
    audio.controls = true;
    audio.style.width = "100%";

    const audioOutDiv = document.createElement("div");
    audioOutDiv.classList.add('audioOutDiv');
    audioOutDiv.appendChild(audio);

    const playButton = document.createElement("button");
    playButton.textContent = "PLAY";
    playButton.onclick = function () {
        if (audio.paused) {
            audio.play();
            playButton.textContent = "PAUSE";
        } else {
            audio.pause();
            playButton.textContent = "PLAY";
        }
    };

    const rewButton = document.createElement("button");
    rewButton.textContent = "<<";
    rewButton.classList.add("rewbutt");
    rewButton.onclick = function () {
        audio.currentTime = 0;
        audio.play();
        playButton.textContent = "PAUSE";
    };

    const hidePlayer = document.createElement("div");
    hidePlayer.classList.add('hideplay');
    hidePlayer.textContent = ". . .";

    audioContainer.appendChild(playButton);
    audioContainer.appendChild(rewButton);
    audioContainer.appendChild(hidePlayer);
    slideDiv.appendChild(audioOutDiv);
    slideDiv.appendChild(audioContainer);

   // console.log("Audio slide bol spracovaný.");
}
    // Apply clip-path style from the product data
    if (item.clip?.value) {
        slideDiv.style.clipPath = item.clip.value;
    }

    // Set data-duration attribute for individual slide durations
    const duration = item.duration?.value || 3000; // Default to 3000 ms
    slideDiv.setAttribute("data-swiper-autoplay", duration);

    // Set data-stop attribute for conditional logic
    slideDiv.setAttribute("data-stop", item.stop?.value || "off");

    wrapper.appendChild(slideDiv);
});

             // Initialize Swiper if it doesn't exist, else update it
             if (!swiper) {
                 initializeSwiper(configData);
             } else {
                 swiper.params.speed = configData.transition;
                 swiper.params.freeMode = configData.freemode;
                 swiper.params.autoplay.delay = configData.speed;


                 if (swiper.autoplay.running) {
                     swiper.autoplay.stop();
                 }

                 // Nastaviť autoplay, ak je zapnutý v konfigurácii
                 if (configData.autoplay) {
                     swiper.params.autoplay.delay = configData.speed;
                     swiper.params.autoplay.disableOnInteraction = true;
                     swiper.autoplay.start();
                 } else {
                     swiper.params.autoplay = false;
                 }

                // console.log(configData.autoplay)
                 //  swiper.update();
                 //  swiper.slideTo(0, 0);
                 swiper.destroy(true, true); // Zničí existujúcu inštanciu Swiperu
                 initializeSwiper(configData); // Znovu inicializuje Swiper

             }


             // Add event listeners to hideplay buttons
             addHidePlayEventListeners();

         },
         onError: function(error) {
             console.error("Error loading product data from websheet:", error);
         }

     });
 }
 
 // Fetch slides data from initial sheet
// toto som zrusil xxx fetchSlidesData("websheet");

 export function reloadSwiperWithNewSheet() {
     const sheetNameInput = document.getElementById("sheetNameInput");
     const sheetName = sheetNameInput.value.trim();
     if (sheetName) {
         fetchSlidesData(sheetName);
        
     }
	 
	 
	 const forFullScreen = document.getElementById("forfullsreen");
            forFullScreen.classList.add('hidden'); // Pridajte fade out efekt

            setTimeout(() => {
                const sheetNameInput = document.getElementById("sheetNameInput");
                const sheetName = sheetNameInput.value.trim();
              //  fetchSlidesData(sheetName); // Načítanie údajov zo zadaného listu

                forFullScreen.classList.remove('hidden'); // Odstráňte fade out, čím spôsobíte fade in
            }, 500); // Čas zodpovedá trvaniu fade out efektu
	 
 }

 function initializeSwiper(configData) {

     const transitionSpeed = configData.transition;
     const autoplayBoolean = configData.autoplay;
     const freemodeBoolean = configData.freemode;
	// Kontrola, či je typ efektu definovaný
      const sltype = configData.stype; // Ensure this is correctly set

     swiper = new Swiper(".swiper", {

         mousewheel: true,
         effect: sltype,
         //simulateTouch: true,
         direction: "vertical",
         loop: true,
         keyboard: {
            enabled: true, // Aktivuje klávesové ovládanie
            onlyInViewport: true, // (voliteľné) Iba ak je Swiper v pohľade
        },

         speed: configData.transition,
         freeMode: freemodeBoolean,
         autoplay: configData.autoplay ? {
             delay: configData.speed,
             disableOnInteraction: true
         } : false
     });

     swiper.on("transitionEnd", function() {

        const activeSlide = swiper.slides[swiper.activeIndex];
         const audio = activeSlide ? activeSlide.querySelector("audio") : null;
      

       
         if (audio) {
             audio.currentTime = 0;
             audio.play();
             const playButton = activeSlide.querySelector(".audio-controls button");
             if (playButton) {
                 playButton.textContent = "PAUSE";
             }
         }
       
     });

     swiper.on("transitionStart", async function() {

        const activeSlideVi = swiper.slides[swiper.activeIndex];
        const video = activeSlideVi ? activeSlideVi.querySelector("video") : null;

        if (video) {

            video.currentTime = 0;
            video.play();
        }

    
       
    });
    
  swiper.on("slideChangeTransitionStart", function () {
    const activeIndex = this.activeIndex;
    const previousIndex = this.previousIndex;
    const nextIndex = activeIndex + 1; // Nasledujúci slide

    const currentSlide = this.slides[activeIndex];
    const previousSlide = this.slides[previousIndex];
    const nextSlide = this.slides[nextIndex];

    // Skontrolujeme, či aktuálny slide obsahuje audio
    const currentAudio = currentSlide.querySelector("audio");

    if (currentAudio) {
       // console.log("Aktuálny slide obsahuje audio, vykonávame fadeOut na všetky ostatné audia.");

        // Pozastavíme všetky ostatné audia s fadeOut efektom
        const allAudios = document.querySelectorAll("audio");
        allAudios.forEach((audio) => {
            if (!audio.paused && audio !== currentAudio) {
                fadeOut(audio, transitionSpeed); // FadeOut na iné prehrávajúce audia
               // console.log("FadeOut na audio:", audio);
            }
        });

        // Spustíme aktuálne audio
       // currentAudio.play();
       // console.log("Prehráva sa aktuálne audio.");
    } else {
       // console.log("Aktuálny slide neobsahuje audio.");
    }

    // Spracovanie animácií na predchádzajúcom slide
    if (previousSlide) {
        const previousHead = previousSlide.querySelector(".head");
        const previousText = previousSlide.querySelector(".headText");
        if (previousHead) {
            previousHead.classList.remove("slideIn");
            previousHead.classList.add("slideOut");
        }
        if (previousText) {
            previousText.classList.remove("slideInText");
            previousText.classList.add("slideOutText");
        }
    }

    // Spracovanie animácií na aktuálnom slide
    if (currentSlide && !currentSlide.classList.contains("swiper-slide-duplicate")) {
        const currentHead = currentSlide.querySelector(".head");
        const currentText = currentSlide.querySelector(".headText");
        if (currentHead) {
            currentHead.classList.remove("slideOut");
            void currentHead.offsetWidth; // Force reflow to restart animation
            currentHead.classList.add("slideIn");
        }
        if (currentText) {
            currentText.classList.remove("slideOutText");
            void currentText.offsetWidth; // Force reflow to restart animation
            currentText.classList.add("slideInText");
        }
    }

    // Spracovanie všetkých slidov - videa a audio
    const slides = swiper.slides;
    slides.forEach((slide) => {
        const video = slide.querySelector("video");
        const audio = slide.querySelector("audio");

        // Ak slide obsahuje video a nie je aktívny, fadeOut video
        if (video && !slide.classList.contains("swiper-slide-active")) {
            fadeOut(video, transitionSpeed);
        }

        // Ak slide obsahuje audio, nastaví sa tlačidlo na "PLAY"
        if (audio) {
            const playButton = slide.querySelector(".audio-controls button");
            if (playButton) {
                playButton.textContent = "PLAY";
            }
        }
    });
});


     swiper.on("slideChangeTransitionEnd", function() {

         // Clean up classes after transition
         const previousSlide = this.slides[this.previousIndex];
         if (previousSlide) {
             const previousHead = previousSlide.querySelector('.head');
             if (previousHead) {
                 previousHead.classList.remove('slideOut');
             }
             const previousText = previousSlide.querySelector('.headText');
             if (previousText) {
                 previousText.classList.remove('slideOutText');
             }
         }

         const activeSlide = swiper.slides[swiper.activeIndex];
         const video = activeSlide.querySelector("video");
         const audio = activeSlide.querySelector("audio");

         if (video && !activeSlide.classList.contains("swiper-slide-active")) {
             video.currentTime = 0;
             video.pause();
         }

         if (video) {
             video.style.transition = "none";
             video.volume = 1;
         }

         if (audio) {
             audio.style.transition = "none";
             audio.volume = 1;
         }
     });

     // Pridanie spracovania klávesových vstupov
 swiper.on("keyPress", (swiper, keyCode) => {
    switch (keyCode) {
    
        case 81: // Kláves Page Up

       
            swiper.slidePrev(); // Prechod na predchádzajúci slide
            break;
        case 87: // Kláves Page Down
            swiper.slideNext(); // Prechod na nasledujúci slide
            break;
    }
});

 }
 
 function fadeOut(media, transitionDuration) {
     let volume = media.volume;
     const fadeOutSteps = 7; // Number of steps for fading out
     const fadeOutIntervalTime = transitionDuration / fadeOutSteps; // Calculate interval time based on transition duration

     const fadeOutInterval = setInterval(() => {
         if (volume > 0.1) {
             volume -= 0.1;
             media.volume = volume;
         } else {
             clearInterval(fadeOutInterval);
             media.pause();
             media.volume = 1;
         }
     }, fadeOutIntervalTime);
 }

 function playAudio(audioSrc) {
     const audio = new Audio(audioSrc);
     audio.play().catch((error) => {
         console.error("Error playing audio:", error);
     });
 }

 function addHidePlayEventListeners() {
     const buttons = document.querySelectorAll(".hideplay");

     buttons.forEach(function(button) {
         button.addEventListener("click", function() {
             const audioOutDivs = document.querySelectorAll(".audioOutDiv");

             audioOutDivs.forEach(function(audioOutDiv) {
                 const audioTag = audioOutDiv.querySelector("audio");
                 if (!audioOutDiv.classList.contains("show")) {
                     audioOutDiv.classList.add("show");

                     if (audioTag) {
                         audioTag.style.backgroundColor = "#f1f3f4";
                     }
                 } else {
                     audioOutDiv.classList.remove("show");
                     if (audioTag) {
                         audioTag.style.backgroundColor = "";
                     }
                 }
             });
         });
     });
 }

 

 function copyToClipboard(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
}