import { closeMenu } from './index.js';  // Importujte funkciu z hlavného súboru
import { openMenu } from './index.js';
export let isMediaLoaded = true;
let isLoadMediaActive = true;  // Globálna premenná na sledovanie stavu
let observer;  // Premenná pre IntersectionObserver
let page = 1; // Začneme s prvou stránkou

// Funkcia na skrytie media-container
function hideMediaContainer() {
  const mediaContainer = document.getElementById('media-container');
  
  
  if (mediaContainer) {
    mediaContainer.style.visibility = 'hidden';
    mediaContainer.style.zIndex = '0'; mediaContainer.style.zIndex = '1005';
   // mediaContainer.innerHTML = ''; // Ak chcete odstrániť obsah, pridajte tento riadok
  } else {
    console.error('mediaContainer element not found');
  }
}

// Funkcia na zobrazenie media-container
function showMediaContainer() {
  const mediaContainer = document.getElementById('media-container');
  if (mediaContainer) {
    mediaContainer.style.visibility = 'visible';
    mediaContainer.style.zIndex = '1005';
  } else {
    console.error('mediaContainer element not found');
  }
}



export function loadMedia() {
  if (!isLoadMediaActive) return;  // Skontrolujeme, či má funkcia pokračovať

  closeMenu(); // Zatvorenie navigačného menu po kliknutí
  document.getElementById('toggle').checked = false; // Zrušenie zaškrtnutia toggle tlačidla
  const perPage = 100; // Nastavíme limit na 50 položiek na stránku
  
  let isLoading = false; // Flag na sledovanie načítania
  const mediaContainer = document.getElementById('media-container');
  const ownmedia = document.getElementById('ownmedia');

  const storedBaseUrl = localStorage.getItem('mediaBaseUrl');
    const defaultBaseUrl = `https://www.divart.sk/wp-json/wp/v2/media?per_page=${perPage}&page=`;

    const fullBaseUrl = storedBaseUrl ? `${storedBaseUrl}/wp-json/wp/v2/media?per_page=${perPage}&page=` : defaultBaseUrl;
    console.log('Uložená URL s cestou k API:', fullBaseUrl);


  function showLoadingIndicator() {
    let loadingIndicator = document.getElementById('loading-indicator');
    let forfullsreen = document.getElementById('forfullsreen');
    if (!loadingIndicator) {
      loadingIndicator = document.createElement('div');
      loadingIndicator.id = 'loading-indicator';
      loadingIndicator.className = 'loading-indicator';
      loadingIndicator.textContent = 'Loading media...click on image to get url';
      forfullsreen.appendChild(loadingIndicator);
    }
    loadingIndicator.style.display = 'block';
  }

  function hideLoadingIndicator() {
    const loadingIndicator = document.getElementById('loading-indicator');
    if (loadingIndicator) {
      loadingIndicator.style.display = 'none';
    }
  }

  function fetchMedia(retryCount = 3, retryDelay = 1000) {
    if (!isLoadMediaActive || isLoading) return; // Skontrolujeme stav pred načítaním
    isLoading = true; // Nastavíme flag načítania
    showLoadingIndicator();
  
    const url = `${fullBaseUrl}${page}`;
  
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.length > 0) {
          displayMedia(data); // Zobrazíme nové médiá
  
          if (data.length < perPage) {
            // Ak načítaná sada nie je úplná, stále pokračujeme, ale označíme poslednú stránku
            console.warn(`Loaded incomplete data set (only ${data.length} items)`);
  
            // Môžeme rozhodnúť, či budeme pokračovať alebo skúsiť znovu túto stránku
            if (retryCount > 0) {
              console.log(`Retrying fetch for the same page... (${retryCount} retries left)`);
  
              // Opakujeme fetch pre rovnakú stránku po určitej dobe
              setTimeout(() => fetchMedia(retryCount - 1, retryDelay), retryDelay);
            } else {
              console.warn('Max retries reached, moving to next page.');
              page++;  // Pokračujeme načítaním ďalšej stránky
            }
          } else {
            // Ak je načítaná sada úplná (100 položiek), pokračujeme ďalej
            page++; // Inkrementujeme stránku
          }
        } else {
          // Ak API nevráti žiadne dáta, zastavíme načítanie
          console.warn('No more media to load.');
          isLoadMediaActive = false;
        }
  
        isLoading = false; // Ukončíme flag načítania
        hideLoadingIndicator();
      })
      .catch(error => {
        console.error('Error fetching media:', error);
        isLoading = false; // Ukončíme flag načítania aj pri chybe
        hideLoadingIndicator();
  
        // Retry mechanizmus: ak fetch zlyhá a máme povolený počet pokusov, skúsime znovu
        if (retryCount > 0) {
          console.log(`Retrying fetch... (${retryCount} retries left)`);
          setTimeout(() => fetchMedia(retryCount - 1, retryDelay), retryDelay);
        } else {
          console.error('Max retries reached. Fetch failed.');
        }
      });
  }
  
// Funkcia na zobrazenie tlačidla na zatvorenie
function createCloseButton() {
  const button = document.createElement('button');
  button.className = 'close-btn';
  button.id = 'close-media-container';
  button.style.zIndex = '2';
  button.style.position = 'fixed';
  button.style.top = '30px';
  button.style.right = '20px';
  button.style.backgroundColor = 'transparent';
  button.style.border = 'none';
  button.style.cursor = 'pointer';

  button.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
  `;

  // Pridajte event listener pre zatvorenie
  button.addEventListener('click', function() {
      hideMediaContainer(); // Skryje media-container
      openMenu();
   
  });

  return button;
}

  function displayMedia(mediaArray) {
    console.log(mediaArray)
    if (!mediaContainer) {
      console.error('No media container found!');
      return;
    }
// Skontrolujte, či tlačidlo už existuje a pridajte ho, ak nie
if (!document.getElementById('close-media-container')) {
  mediaContainer.appendChild(createCloseButton());
}

    mediaArray.forEach(media => {
      if (!media || !media.source_url || !media.media_type) {
        console.error('Invalid media item:', media);
        return;
      }

      const mediaElement = document.createElement('div');
      mediaElement.classList.add('media-item');

      let hasContent = false; // Flag na kontrolu, či sa pridalo médium



      switch (media.media_type) {
        case 'image':
          const img = document.createElement('img');
          const thumbnailUrl = media.media_details.sizes.thumbnail ? media.media_details.sizes.thumbnail.source_url : media.source_url;
          img.src = thumbnailUrl;
          img.alt = media.alt_text || 'Image';
          img.loading = 'lazy'; // Pridanie lazy loading
          img.dataset.fullsize = media.source_url; // Uložíme URL originálneho obrázka do datasetu
          img.addEventListener('click', function() {
            // Skopíruj URL originálneho obrázka do schránky
            navigator.clipboard.writeText(this.dataset.fullsize)
              .then(() => {
                // Zobrazíme animáciu po kliknutí
                showCopiedText(this);
              })
              .catch(err => {
                console.error('Failed to copy image URL:', err);
              });
          });
          mediaElement.appendChild(img);
          hasContent = true; // Nastavíme flag, že sa pridalo médium
          break;

        case 'video':
          const video = document.createElement('video');
          video.src = media.source_url;
          video.controls = true;
          video.loading = 'lazy'; // Pridanie lazy loading
          mediaElement.appendChild(video);
          hasContent = true; // Nastavíme flag, že sa pridalo médium
          break;

        case 'audio':
          // cccccccc
       
          const audio = document.createElement('audio');
          audio.src = media.source_url;
          audio.controls = true;
          audio.loading = 'lazy'; // Pridanie lazy loading
          mediaElement.appendChild(audio);
          hasContent = true; // Nastavíme flag, že sa pridalo médium
          break;

          case 'application':
            case 'file':
                const fileExtension = media.source_url.split('.').pop().toLowerCase();
                
                // Vytvorenie div elementu pre médium
                const mediaDiv = document.createElement('div');
                 
                mediaDiv.classList.add('anotherMediaItem');
                // Pridanie názvu súboru
                const titleText = document.createElement('div');
                titleText.style.color = 'white'; // Biela farba textu
                titleText.textContent = media.title.rendered || 'Unknown File';
                mediaDiv.appendChild(titleText);
            
                // Pridanie typu súboru
                const typeText = document.createElement('div');
                typeText.style.color = 'white'; // Biela farba textu
                typeText.textContent = fileExtension.toUpperCase(); // Typ súboru
                mediaDiv.appendChild(typeText);
            
                // Pridanie event listenera pre kliknutie na div
                mediaDiv.addEventListener('click', function() {
                    const urlToCopy = media.source_url;
                    navigator.clipboard.writeText(urlToCopy)
                        .then(() => {
                            // Zobrazíme animáciu po kliknutí
                            showCopiedText(this);
                        })
                        .catch(err => {
                            console.error('Failed to copy URL:', err);
                        });
                });
                
                mediaElement.appendChild(mediaDiv);
                hasContent = true; // Nastavíme flag, že sa pridalo médium
                break;
            
            
        default:
         // console.error('Unknown media type:', media);
      }

      // Ak sa do mediaElement pridalo médium, pridáme ho do kontajnera
      if (hasContent) {
        mediaContainer.appendChild(mediaElement);
      }
    });

    // Po pridaní nových médií aktualizujeme pozorovateľa
    updateObserver();
  }

  function showCopiedText(element) {
    // Vytvoríme element na zobrazenie textu
    const copiedText = document.createElement('div');
    copiedText.className = 'copied-text';
    copiedText.textContent = 'Copied!';
  
    // Umiesťujeme text na rovnakú pozíciu ako element
    element.parentElement.appendChild(copiedText);
  
    // Odstránime text po 2 sekundách
    setTimeout(() => {
      copiedText.remove();
    }, 2000);
  }
  
  

  // Vytvoríme IntersectionObserver na načítanie ďalších dát pri scrollovaní
  observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting) {
      fetchMedia(); // Načítame ďalšie médiá
    }
  }, {
    rootMargin: '100px', // Skúsiť načítať o 100px skôr, než je viditeľné
  });

  function updateObserver() {
    if (mediaContainer && mediaContainer.lastElementChild) {
      observer.observe(mediaContainer.lastElementChild);
    } else {
      console.warn('No last element found for observer.');
    }
  }

  if (isMediaLoaded) {
    const removeSwiper = document.getElementById('wrapper');
    removeSwiper.innerHTML = '';
    closeMenu();

    
    fetchMedia();
    if (mediaContainer) {
    //  mediaContainer.innerHTML = '';
    } else {
      console.error('mediaContainer element not found');
    }

    // Počkáme, kým sa stránka načíta, a potom pripojíme listener
 
    if (ownmedia) {
      ownmedia.addEventListener('click', function() {
        showMediaContainer(); // Zobrazíme media-container
        isLoadMediaActive = true; // Nastavíme flag na true, aby sa načítali médiá
        fetchMedia(); // Načítame ďalšie médiá
      //  console.log('clicked');
      });
    } else {
      console.error('ownmedia element not found');
    }
  }
 


  
}

document.addEventListener('DOMContentLoaded', function() {
  const mediaContainer1 = document.getElementById('media-container');
  const toggleButtonINMEDIA = document.getElementById('toggle');

  // Toggle button event listener
  toggleButtonINMEDIA.addEventListener('click', function() {
    hideMediaContainer(); // Skryjeme media-container
   
  });

 
});
